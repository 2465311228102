<template>
<div>
  <multiselect
  deselect-label=""
  track-by="value"
  class="multiselect-custom col-span-4"
  style="background: rgb(55,69,93); max-width: 635px; margin: 4px; padding: 4px; overflow-x: scroll; overflow-y: hidden;"
  :multiple="true"
  v-model="selectUser"
  label="text"
  :max-height="250"
  placeholder=""
  :options="availableUsers"
  :searchable="false"
  :allow-empty="true"
/>
</div>
</template>
<script>
import Vue from "vue";
import Multiselect from 'vue-multiselect';
import accountModule from '@/store/modules/accountModule';
export default {
   components: {
    Multiselect
  },

 data() {
        return {
           selectUser: [],
            cancelBeforeStart: true,
            highlightAllOnFocus: true
        };
    },

  computed: {
    availableUsers() {
      return accountModule.reassignmentList.map((i) => ({
        // @ts-ignore
        text: i.name,
        value: i.email,
      }));
    },

  },
}
</script>

<style scoped>
.multiselect-custom >>> .multiselect__tags {
    background: rgb(55,69,93);
    border-color: transparent;
    padding: 0;
}

.multiselect-custom >>> .multiselect__select {
  display: none;
}

.multiselect-custom >>> .multiselect__content-wrapper{
  position: fixed;
}
</style>
